import { Injectable } from "@angular/core";
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot
} from "@angular/router";
import { ConnectionService } from "@services/connection.service";
import { OAuthService } from "angular-oauth2-oidc";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
	constructor(
		private OAUTH: OAuthService,
		private CONNECT: ConnectionService,
		private router: Router
	) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> {
		return this.CONNECT.canActivateProtectedRoutes$.pipe(
			map(canActivate => {
				// remove OIDC queryparams from URL to avoid reload error (bad token refresh)
				if (this.OAUTH.hasValidAccessToken()) {
					if (!!next.queryParams.state) {
						this.router.navigate([next.url[0].path], {
							queryParamsHandling: "merge"
						});
					}
					return true;
				}
				return false;
			})
		);
	}
}
